/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  metadatas: {
    name: 'Sur la Route',
  },
  useShell: [
    'bb-default',
    {
      view: {
        featuredContents: false,
        allowHeadingOnMobile: false,
      },
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '663e4b3eec91d758f8dd4e8f',
          i18n: {
            fr: {
              title: 'La minute sécurité',
              description: `Tous les sujets prévention et sécurité à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#E20025',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id === '663dcddaf8ba8461c53b6ee1') return true
              if (filter.id === '663dce38587f1a259e4a37cd') return true
              if (filter.id === '663dcea2587f1a2adb4a37dd') return true
              if (filter.id === '67a3925f490bde1a98f5f5a4') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '663e4b4e587f1a9dee4a40c0',
          i18n: {
            fr: {
              title: `Histoires de compagnons`,
              description: `Tous vos portraits à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#7AA536',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id === '663dcf84f8ba84b8823b6f45') return true
              if (filter.id === '663dcfec587f1a4e894a3827') return true
              if (filter.id === '67a3925f490bde1a98f5f5a4') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '663e4b57587f1a41054a40c6',
          i18n: {
            fr: {
              title: `En direct des régions`,
              description: `Tous les reportages de vos régions à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#307BFF',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id === '663dcf84f8ba84b8823b6f45') return true
              if (filter.id === '663dcfec587f1a4e894a3827') return true
              if (filter.id === '67a3925f490bde1a98f5f5a4') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '66ebcc7eb44241061d24f2d1',
          i18n: {
            fr: {
              title: `Emissions spéciales`,
              description: `Toutes les émissions thématiques à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#F2AB1C',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '673e0938bdb12a3306c32308',
          i18n: {
            fr: {
              title: `Dédicaces`,
              description: `Toutes vos dédicaces à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#A152E5',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
      ],
    },
  ],
  nuxt: {
    robots: {
      UserAgent: '*',
      Allow: '/',
    },
    head: {
      title: 'Les podcasts de Vinci Construction',
      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
        },
        {
          hid: 'description',
          name: 'description',
          content: `A la rencontre des collaborateurs de VINCI`,
        },
        {
          'http-equiv': 'X-UA-Compatible',
          content: 'IE=edge',
        },
        {
          'http-equiv': 'X-UA-Compatible',
          content: 'IE=edge',
        },
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'index,follow',
        },
        {
          hid: 'ogSiteName',
          name: 'og:site_name',
          content: 'VINCI Construction division Route',
        },
        {
          hid: 'ogDescription',
          name: 'og:description',
          content: 'A la rencontre des collaborateurs de VINCI',
        },
        {
          hid: 'ogType',
          name: 'og:type',
          content: 'website',
        },
      ],
    },
  },
}
