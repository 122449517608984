const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    hasAutoplayContent: true,
    hasSuggestedContent: true,
    WFeedBack: {
      secondFeedback: {
        title: 'Enregistrer un message',
        name: 'Dédicaces audio',
        type: 'audio',
        maxDuration: 60,
        headCaption: `Laissez votre message pour qu’il soit diffusé dans Le Quart d’heure Compagnons !`,
        subCaption: `Je suis informé que Vinci traite les données recueillies pour
           permettre la diffusion de votre dédicace. Pour en savoir plus,
           reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message à bien été envoyé.`,
        pseudonym: {
          label: 'Nom',
          placeholder: 'Dupont',
        },
        recorderForm: [
          {
            label: 'Prénom',
            placeholder: 'Martin',
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Métier',
            placeholder: "conducteur d'engins",
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '6 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label: 'Agence',
            placeholder: "agence Eurovia d'Aubervilliers",
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'J’accepte l’utilisation de mes données personnelles',
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
    siteId: '63bebd0923dd78b2fe9baf55',
    enableSplashScreen: 'wavy',
    enableExperimentalBlocks: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    metadatas: {
      name: "Sur la route, le quart d'heure compagnons",
      colorPrimary: '#184a8b',
      colorAccent: '#e81e33',
      supportEmailAddress: 'support@bubblecast.co',
    },
    analytics: {
      matomo: '35',
    },
    useLTS: true,
  },

  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
