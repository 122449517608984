<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#F0F0F0"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            <div class="preambule">
              <i>Sur la Route</i>, c'est la radio 100% VINCI Construction de la
              division Route France, lancée lors des Safety Days 2024. Depuis le
              3 février, les émissions quotidiennes, c'est fini ! Cependant, les
              podcasts restent en ligne ! Vous pouvez les utiliser pour écouter
              les bonnes pratiques en matière de prévention, animer vos
              réunions, ou encore découvrir un métier, une activité, une
              région... Bonne écoute !
              <br />
            </div>
            <div class="borderlines">
              <b-borderline height="4px" />
            </div>
            <div
              class="text-alternate presentation-coll"
              :class="{
                'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
                'body-2': $voicer.isMobile,
              }"
            >
              <div class="pl-2 pr-2 pt-1">
                Les enjeux prévention, santé, sécurité (sensibilisation, bonnes
                pratiques, formations, etc)
              </div>
              <v-btn
                ref="btn"
                color="accent"
                rounded
                depressed
                class="ma-2 white--text"
                href="/universe/663e4b3eec91d758f8dd4e8f"
              >
                <v-icon color="#ffffff" class="mr-2"> mdi-hard-hat </v-icon>
                La minute sécurité
              </v-btn>
            </div>
            <div
              class="text-alternate presentation-coll"
              :class="{
                'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
                'body-2': $voicer.isMobile,
              }"
            >
              <div class="pl-2 pr-2 pt-1">
                Les témoignages des compagnons de la division (anecdotes,
                portraits, métiers, etc)
              </div>
              <v-btn
                ref="btn"
                color="#7AA536"
                rounded
                depressed
                class="ma-2 white--text"
                href="/universe/663e4b4e587f1a9dee4a40c0"
              >
                <v-icon color="#ffffff" class="mr-2">
                  mdi-account-supervisor
                </v-icon>
                Histoire des compagnons
              </v-btn>
            </div>
            <div
              class="text-alternate presentation-coll"
              :class="{
                'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
                'body-2': $voicer.isMobile,
              }"
            >
              <div class="pl-2 pr-2 pt-1">
                Les reportages régionaux (vie de chantier, spécificités des
                engins, environnement, etc)
              </div>
              <v-btn
                ref="btn"
                color="#307BFF"
                rounded
                depressed
                class="ma-2 white--text"
                href="/universe/663e4b57587f1a41054a40c6"
              >
                <v-icon color="#ffffff" class="mr-2"> mdi-map-marker </v-icon>
                En direct des régions
              </v-btn>
            </div>
            <div class="borderlines">
              <b-borderline height="4px" />
            </div>
            <div class="preambule">
              L'équipe de la radio <i>Sur la Route</i> remercie toutes les
              équipes pour leur accueil et participation à ce projet !
            </div>
          </div>
        </div>
      </v-sheet>
    </v-container>
    <v-container
      v-if="$route.path.includes('6622309dcba11a804b075360')"
      class="pa-0"
    >
      <v-sheet
        ref="sheet"
        color="#F0F0F0"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1 preambule">
            Tous les contenus dédiés à la prévention, la santé et la sécurité
            sont classés par activité et par risque majeur. <br />Utilisez le
            bouton « FILTRER » ci-dessous ou le bouton « RECHERCHER » en haut de
            la page.
            <br />
          </div>
        </div>
      </v-sheet>
    </v-container>
    <v-container
      v-if="$route.path.includes('673e0938bdb12a3306c32308')"
      class="pa-0"
    >
      <v-sheet
        ref="sheet"
        color="#F0F0F0"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Pour laisser une dédicace à votre équipe, cliquez sur le bouton
            "Enregistrer un message" ou contactez-nous au 06 48 73 78 26
            <br />
            <v-btn color="accent" @click.stop="showSecondFeedbackModal = true">
              Enregistrer un message
            </v-btn>
            <w-feedback-popup
              id="secondFeedback"
              v-model="showSecondFeedbackModal"
            />
          </div>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showFirstFeedbackModal: false,
      showSecondFeedbackModal: false,
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },
  methods: {
    handleOpen(e) {
      this.$spoke
        .collection('contents')
        .getOne('66cf3042e78d0926cc3af033')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
    handleOpenBis(e) {
      this.$spoke
        .collection('contents')
        .getOne('665086b709e3be3a78f4256f')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .presentation-coll {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 601px) {
  .presentation-coll {
    flex-direction: row-reverse;
  }
}
.w-block__pre-heading {
  font-weight: bold;
  justify-content: center;
  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
a {
  color: #e20025;
}
.link {
  color: #307bff;
}
.presentation-coll {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.borderlines {
  margin: auto;
  padding: 10px 0px;
  .b-borderline {
    display: inline-block;
    width: 42px;
    margin: 0 4px;
    &__line {
      border-radius: 6px;
    }
  }
}
.preambule {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
</style>
