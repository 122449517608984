var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.currentPage === 'default')?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#F0F0F0"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_c('div',{staticClass:"preambule"},[_c('i',[_vm._v("Sur la Route")]),_vm._v(", c'est la radio 100% VINCI Construction de la\n            division Route France, lancée lors des Safety Days 2024. Depuis le\n            3 février, les émissions quotidiennes, c'est fini ! Cependant, les\n            podcasts restent en ligne ! Vous pouvez les utiliser pour écouter\n            les bonnes pratiques en matière de prévention, animer vos\n            réunions, ou encore découvrir un métier, une activité, une\n            région... Bonne écoute !\n            "),_c('br')]),_vm._v(" "),_c('div',{staticClass:"borderlines"},[_c('b-borderline',{attrs:{"height":"4px"}})],1),_vm._v(" "),_c('div',{staticClass:"text-alternate presentation-coll",class:{
              'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
              'body-2': _vm.$voicer.isMobile,
            }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n              Les enjeux prévention, santé, sécurité (sensibilisation, bonnes\n              pratiques, formations, etc)\n            ")]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"accent","rounded":"","depressed":"","href":"/universe/663e4b3eec91d758f8dd4e8f"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-hard-hat ")]),_vm._v("\n              La minute sécurité\n            ")],1)],1),_vm._v(" "),_c('div',{staticClass:"text-alternate presentation-coll",class:{
              'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
              'body-2': _vm.$voicer.isMobile,
            }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n              Les témoignages des compagnons de la division (anecdotes,\n              portraits, métiers, etc)\n            ")]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"#7AA536","rounded":"","depressed":"","href":"/universe/663e4b4e587f1a9dee4a40c0"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v("\n                mdi-account-supervisor\n              ")]),_vm._v("\n              Histoire des compagnons\n            ")],1)],1),_vm._v(" "),_c('div',{staticClass:"text-alternate presentation-coll",class:{
              'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
              'body-2': _vm.$voicer.isMobile,
            }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n              Les reportages régionaux (vie de chantier, spécificités des\n              engins, environnement, etc)\n            ")]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"#307BFF","rounded":"","depressed":"","href":"/universe/663e4b57587f1a41054a40c6"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-map-marker ")]),_vm._v("\n              En direct des régions\n            ")],1)],1),_vm._v(" "),_c('div',{staticClass:"borderlines"},[_c('b-borderline',{attrs:{"height":"4px"}})],1),_vm._v(" "),_c('div',{staticClass:"preambule"},[_vm._v("\n            L'équipe de la radio "),_c('i',[_vm._v("Sur la Route")]),_vm._v(" remercie toutes les\n            équipes pour leur accueil et participation à ce projet !\n          ")])])])])],1):_vm._e(),_vm._v(" "),(_vm.$route.path.includes('6622309dcba11a804b075360'))?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#F0F0F0"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1 preambule"},[_vm._v("\n          Tous les contenus dédiés à la prévention, la santé et la sécurité\n          sont classés par activité et par risque majeur. "),_c('br'),_vm._v("Utilisez le\n          bouton « FILTRER » ci-dessous ou le bouton « RECHERCHER » en haut de\n          la page.\n          "),_c('br')])])])],1):_vm._e(),_vm._v(" "),(_vm.$route.path.includes('673e0938bdb12a3306c32308'))?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#F0F0F0"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n          Pour laisser une dédicace à votre équipe, cliquez sur le bouton\n          \"Enregistrer un message\" ou contactez-nous au 06 48 73 78 26\n          "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){$event.stopPropagation();_vm.showSecondFeedbackModal = true}}},[_vm._v("\n            Enregistrer un message\n          ")]),_vm._v(" "),_c('w-feedback-popup',{attrs:{"id":"secondFeedback"},model:{value:(_vm.showSecondFeedbackModal),callback:function ($$v) {_vm.showSecondFeedbackModal=$$v},expression:"showSecondFeedbackModal"}})],1)])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }